import React, { Fragment, useRef } from "react";
import ReactDOM from "react-dom";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";

const FormSelectField = ({
  as,
  md,
  controlId,
  label,
  name,
  type,
  inputGroupPrepend,
  children,
  customClass,
  hint,
	hintMessage,
  hintLetter,
  disabled
}) => {
  const labelHintEl = useRef(null);
  const hintFormGroup = useRef(null);

  
	const showHint = (e) => {
		const hintInputGroup = hintFormGroup.current;
		const node = ReactDOM.findDOMNode(hintInputGroup)
		node.classList.toggle('hint-active');
		e.stopPropagation();
	};

  return ( 
    <Field
      name={name}>
      {({ field, form }) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (
          <Form.Group ref={hintFormGroup} as={as} md={md} controlId={controlId} className={customClass ? customClass : ""}>
            {label ?
              <Fragment>
							<Form.Label>{label}</Form.Label>
              {hint ? 
								<div className="label-hint-wrapper">
									<div className="label-hint" ref={labelHintEl} onClick={showHint}>
										<span>{hintLetter ? hintLetter : "?"}</span>
									</div> 
								</div>
								: null}
              </Fragment>
						: null
						}
            { hint ?
							<div className="label-hint-message">{hintMessage}</div>
							:
							null
						}
            <InputGroup className={(inputGroupPrepend) ? "input-has-icon" : ""}>
              {inputGroupPrepend}
              <Form.Control
                {...field}
                className="selectpicker"
                type={type}
                isValid={form.touched[field.name] && isValid && field.value !== ''}
                isInvalid={isInvalid}
                feedback={form.errors[field.name]}
                as="select"
                autoFocus={name==="authorized_applicant_type"}
                bsPrefix="form-control data-hj-allow"
                disabled={disabled}
              >
                {children}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {form.errors[field.name]}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      }}
    </Field>
  );
};

FormSelectField.defaultProps = {
  type: "select",
  inputGroupPrepend: null
};

export default FormSelectField;
