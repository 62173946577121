
import forge from 'node-forge';
forge.options.usePureJavaScript = true;

// Start the main app logic.

const payTracePublicKey = `-----BEGIN PUBLIC KEY-----${process.env.REACT_APP_PAYTRACE_PUBLIC_KEY}-----END PUBLIC KEY-----`;
const paytrace = (function () {
  var pub = null;
  var module = null; // to make hoisting explicit

  module = {
    setKey: function () {
      pub = forge.pki.publicKeyFromPem(payTracePublicKey ?? '');
      return this;
    },
    encryptValue: function (data) {

      if (!pub) { throw new Error("No public key was provided (PayTrace E2EE)."); }
      return forge.util.encode64(pub.encrypt(data));
    }
  };

  return module;
})();

export default paytrace;