import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './acls.scss';
import './App.scss';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module'

hotjar.initialize(2021197,6);

const tagManagerArgs = {
  gtmId: 'GTM-5RDKLC2',
  dataLayerName: 'CLSDataLayer',
}

// const tagManagerArgs = {
//   gtmId: 'GTM-NJT3DDT',
//   dataLayer: {
//       staticData: 'loan-application',
//   },
// };

TagManager.initialize(tagManagerArgs)

const rootEl = document.getElementById('root')

ReactDOM.render(
<BrowserRouter>
<App />
</BrowserRouter>
, rootEl);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(
      <BrowserRouter>
      <NextApp />
      </BrowserRouter>,
      rootEl
    )
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
