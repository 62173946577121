import React, { useEffect, useState, Fragment } from "react";
import ReactDOM from "react-dom";
import { Col, Form } from "react-bootstrap";
import FormTextField from "./form-fields/FormTextField";
import FormSelectField from "./form-fields/FormSelectField";
import { useSpring, animated } from "react-spring";
import * as EnrollmentAPI from "../EnrollmentAPI";
import { _debounce } from "../utils/helper";

	const AnimatedRow = animated(Form.Row);

	const animation = {
		transform: "translate3d(0px,0,0) scale(1) rotateX(0deg)"
	};



const StepOne =(stepProps) => {
	const { 
		values, 
		touched, 
		elRef, 
		setFieldError, 
		setFieldValue, 
		onShowStreet, 
		streetSectionDisplayed, 
		onFbCaChanged,
		onAuthListPopulated,
		authListPopulated,
		//authListPopulatedSelected
	} = stepProps;
	const props = useSpring(animation);
	let [oriFound, setOriFound] = useState(values.ori ? true : false);
	let [currentORI, oriChangedTo] = useState('');
	let [currentFBICA, fbicaChangedTo] = useState('');
	let [authorizedORI, setAuthorizedList] = useState(authListPopulated);
	let [oriAutoList, setOriAutoList] = useState([]);
	let [oriApiResData, setOriApiRes] = useState([]);
	let [oriAutoListOpened, setOriAutoListOpen] = useState(false);
	let [selectedAutoOri, setAutoOri] = useState('');
	let [selectedAuthorized, setAuthorizedType] = useState('');
	const [streetVisible, displayStreet] = useState(streetSectionDisplayed);
	
	useEffect(() => {

		const formDom = ReactDOM.findDOMNode(elRef.current)
		
		const oriInputEl = formDom.querySelector('input[name="ori"]')

		formDom.addEventListener('click', (e)=>{
			
		const hintActive = formDom.querySelector('.hint-active');
		const hintMsgEl = formDom.querySelector('.label-hint-message');
		const labelHintSpanEl = formDom.querySelector('.label-hint span');

			if ( 
				hintActive && 

				e.target && hintMsgEl && 
				e.target !== labelHintSpanEl 
				) {

				hintActive.classList.remove('hint-active')
			}	
			
			//autocomplete list hide on click outside 
			const autoCompleteUlEl = formDom.querySelector('.autocomplete-list')

			if (autoCompleteUlEl !== null ) {
				if (e.target === autoCompleteUlEl || e.target === oriInputEl) {
					return true
				} else {
					
					setTimeout(()=>{
						setOriAutoList([])
						setOriAutoListOpen(false)
					}, 500)
				}
			}

		})

		formDom.addEventListener('keyup', (e)=> {
			const autoCompleteUlEl = formDom.querySelector('.autocomplete-list')
			if ( autoCompleteUlEl && (e.keyCode === 9 ) ) {
				
				setOriAutoList([])
				setOriAutoListOpen(false)
			}
		})

		const { offsetTop } = elRef.current
		try {
		
			//trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
			window.scroll({
				top: offsetTop - 90,
				left: 0,
				behavior: 'smooth',
			});
    } catch (error) {
      // just a fallback for older browsers
			window.scrollTo(0, 0);
    }
	}, [elRef])


	const showStreetSectionHandler = (e) => {
		onShowStreet()
		displayStreet(true)

		const formDom = ReactDOM.findDOMNode(elRef.current)
		e.preventDefault()

		const showMoreOptions = formDom.querySelector('.show-more-options');
		showMoreOptions.classList.add('d-none')
	}

	const oriChangeHandler = (val) => {
		val && checkORIAPI(val)
	}
	const debouncedSend = _debounce(oriChangeHandler, 500)

	const checkORIAPI = async (ori) => {
		
		if (ori !== currentORI) {
			oriChangedTo(ori)
			try {
				const response = await EnrollmentAPI.checkOri(ori);      
				const jsonResponse = await response.json();
				if ( jsonResponse.data.length ) {
					setOriApiRes(jsonResponse.data)
					setFieldValue('authorized_applicant_type', '');
					setOriFound(true)
					const listData = jsonResponse.data.map((item, index)=> <option key={index} value={item.auth_applicant_type}>{item.auth_applicant_type}</option>)
					setAuthorizedList(listData)
					onAuthListPopulated(listData)
					
					//set agency name
					setFieldValue('agency_name', jsonResponse.data[0].agency_name)
				} else {
					setOriFound(false)
					setAuthorizedList([])
					onAuthListPopulated([])
					setAuthorizedType('')
					setFieldValue('authorized_applicant_type', (!selectedAuthorized ? '' : values.authorized_applicant_type))

					//reset agency name
					setFieldValue('agency_name', '')
				}
			} catch (error) {
				//TODO Show Generic Error Message to User
				console.log(error)
				setOriFound(false)
				values.authorized_applicant_type = ''
			}

			// AutoComplete Request
			if (ori.length > 2 && ori !== selectedAutoOri) {
				try {
					const response = await EnrollmentAPI.oriAutoComplete(ori);      
					const jsonResponse = await response.json();
					
					if ( jsonResponse.data.data.length ) {
						// TODO Remove This - testing only
						const autoList = jsonResponse.data.data.map((item, index)=> <li key={index} onClick={()=>{setFieldValue('ori', item.ori); setAutoOri(item.ori); checkORIAPI(item.ori); setOriAutoList([]); setOriAutoListOpen(false)} }> {item.ori}</li>)
						setOriAutoList(autoList)
						setOriAutoListOpen(true)
	
					} else {
						setOriAutoList([])
						setOriAutoListOpen(false)
					}
				} catch (error) {
					//TODO Show Generic Error Message to User
					console.log(error)
				}
			}
		}

  }

	const validateAgencyName = () => {
		if (values.agency_name && values.agency_name.match(/\d+$/)) setFieldError('agency_name', 'Letters only allowed.')
	}

	const validateTelephone = () => {
		if (values.agency_contact_telephone_number && values.agency_contact_telephone_number.match(/[A-Za-z]+$/)) setFieldError('agency_contact_telephone_number', 'Numbers only allowed.')
	}

	const getFBICA = (oriVal, applicantTypeVal, apiRes) => {
		
		if (oriVal && applicantTypeVal && apiRes ) {
			const matched = apiRes.filter(data=> data.ori === oriVal && data.auth_applicant_type === applicantTypeVal)

			if(matched[0] && matched[0].fb_ca !== currentFBICA) {
				fbicaChangedTo(matched[0].fb_ca)
				onFbCaChanged(matched[0].fb_ca)
			}
		
		}
		
	}
	return (

		<AnimatedRow style={props}>
			<Col md="12">
			<h3 className="section-heading mt-4">Application Submission</h3>
			</Col>
			
			<FormTextField
				as={Col}
				md="6"
				customClass="pll-1 prr-1"
				controlId="validationFormiklastOri"
				label="ORI *"
				type="text"
				name="ori"
				minLength={5}
				maxLength={9}
				delayedChanged={(e)=>debouncedSend(e)}
				setFieldValue={setFieldValue}
				onChange={()=> {
					setTimeout(()=> {
						values.authorized_applicant_type = (touched['ori'] && !values.ori) ? '' : values.authorized_applicant_type
						touched['ori'] && !values.ori && setOriFound(false)
						!values.ori && setFieldValue('agency_name', '')
						}, 500)
					}
						
				}
				autoComplete={true}
				autoCompleteData={oriAutoList}
				listOpened={oriAutoListOpened}
				hint={true}
				hintMessage={<Fragment>This number is located on the top left of your <a href="https://www.certifixlivescan.com/request-for-live-scan-forms.html" target="blank">Request for Live Scan Form</a> and cannot be changed at a later time.</Fragment>}
				hintLetter="!"
				customInValid={!oriFound}
				customInValidMsg="Invalid ORI"
			/>
			
			<FormSelectField
				as={Col}
				md="6"
				controlId="validationFormikauthAppType"
				label="Authorized Applicant Type *"
				type="text"
				name="authorized_applicant_type"
				setFieldValue={setFieldValue}
				onChange={
					setTimeout(()=> {
						setAuthorizedType(values.authorized_applicant_type)
						getFBICA(values.ori, values.authorized_applicant_type, oriApiResData)		
					}, 500)
				}
				hint={true}
				hintMessage="Make sure to select the correct Application Type, as we cannot change this at a later time."
				hintLetter="!"
				disabled={!oriFound}
				>
				<option value="" disabled></option>
				{authListPopulated ? authListPopulated : authorizedORI}
				
			</FormSelectField>

			<FormTextField
				as={Col}
				md="12"
				controlId="validationFormiktypeOfLicense"
				label="Type of License *"
				type="text"
				name="type_of_licence"
				setFieldValue={setFieldValue}
				maxLength={30}
			/>
			

			<Col md="6">
				<div className={`show-more-options ${streetVisible ? 'd-none':'' }`}><a href="#/" style={{color: '#08aae8', cursor: 'pointer'}} onClick={showStreetSectionHandler}>+ Show more</a></div>
			</Col>

			{
				streetVisible ?
				<Fragment>
					
					<Col md="12">
						<h3 className="section-heading mt-4">Contributing Agency Information</h3>
					</Col>

					<FormTextField
						as={Col}
						md="6"
						controlId="validationFormikAgencyName"
						label="Authorized Agency to Receive Criminal Record"
						type="text"
						name="agency_name"
						setFieldValue={setFieldValue}
						onChange={
								setTimeout(()=> {
									validateAgencyName()
								}, 500)
							}
						maxLength={25}
						disabled={true}
					/>
			
					<FormTextField
						as={Col}
						md="6"
						controlId="validationFormikAgencyMailCode"
						label="Mail Code"
						placeHolder="e.g. 12345"
						type="text"
						name="agency_mail_code"
						setFieldValue={setFieldValue}
						maxLength="5"
					/>

					<Col md="12">
						<label className="mt-3"><strong>Street Address or P.O. Box </strong></label>
					</Col>
					
					<FormTextField
						as={Col}
						md="4"
						controlId="validationFormikAgencyStreetNumber"
						label="Street Number"
						type="text"
						name="agency_street_number"
						setFieldValue={setFieldValue}
						maxLength={10}
					/>
					
					<FormSelectField
						as={Col}
						md="2"
						customClass="pll-1"
						controlId="validationFormikAgencyStreetDirection"
						label="Street Direction"
						type="text"
						name="agency_street_direction"
						setFieldValue={setFieldValue}
						>
						<option value=""></option>
						<option value="N">N</option>
						<option value="S">S</option>
						<option value="E">E</option>
						<option value="W">W</option>
						<option value="NE">NE</option>
						<option value="NW">NW</option>
						<option value="SE">SE</option>
						<option value="SW">SW</option>
						
					</FormSelectField>
					
					<FormTextField
						as={Col}
						md="6"
						controlId="validationFormikAgencyStreetName"
						label="Street Name"
						type="text"
						name="agency_street_name"
						setFieldValue={setFieldValue}
						maxLength={20}
					/>

			
					<FormTextField
						as={Col}
						md="6"
						customClass="prr-1"
						controlId="validationFormikAgencyCity"
						label="City"
						type="text"
						name="agency_city"
						setFieldValue={setFieldValue}
						maxLength="25"
					/>
					<FormSelectField
						as={Col}
						md="3"
						customClass="prr-1 pll-1"
						controlId="validationFormikAgencyState"
						label="State"
						type="text"
						name="agency_state"
						setFieldValue={setFieldValue}
						>
						<option value="" disabled></option>
						<option value="AL">AL</option>
						<option value="AK">AK</option>
						<option value="AZ">AZ</option>
						<option value="AR">AR</option>
						<option value="CA">CA</option>
						<option value="CO">CO</option>
						<option value="CT">CT</option>
						<option value="DE">DE</option>
						<option value="DC">DC</option>
						<option value="FL">FL</option>
						<option value="GA">GA</option>
						<option value="HI">HI</option>
						<option value="ID">ID</option>
						<option value="IL">IL</option>
						<option value="IN">IN</option>
						<option value="IA">IA</option>
						<option value="KS">KS</option>
						<option value="KY">KY</option>
						<option value="LA">LA</option>
						<option value="ME">ME</option>
						<option value="MD">MD</option>
						<option value="MA">MA</option>
						<option value="MI">MI</option>
						<option value="MN">MN</option>
						<option value="MS">MS</option>
						<option value="MO">MO</option>
						<option value="MO">MO</option>
						<option value="NE">NE</option>
						<option value="NV">NV</option>
						<option value="NH">NH</option>
						<option value="NJ">NJ</option>
						<option value="NM">NM</option>
						<option value="NY">NY</option>
						<option value="NC">NC</option>
						<option value="ND">ND</option>
						<option value="OH">OH</option>
						<option value="OK">OK</option>
						<option value="OR">OR</option>
						<option value="PA">PA</option>
						<option value="RI">RI</option>
						<option value="SC">SC</option>
						<option value="SD">SD</option>
						<option value="TN">TN</option>
						<option value="TX">TX</option>
						<option value="UT">UT</option>
						<option value="VT">VT</option>
						<option value="VA">VA</option>
						<option value="WA">WA</option>
						<option value="WV">WV</option>
						<option value="WI">WI</option>
						<option value="WY">WY</option>			
					</FormSelectField>		
						
					<FormTextField
						as={Col}
						md="3"
						customClass="pll-1"
						controlId="validationFormikAgencyZipcode"
						label="Zip Code"
						type="text"
						maxLength={10}
						name="agency_zip_code"
						setFieldValue={setFieldValue}
					/>	
					
					
					
					<FormTextField
						as={Col}
						md="6"
						controlId="validationFormikAgencyContact"
						label="Contact Name"
						type="text"
						name="agency_contact_name"
						setFieldValue={setFieldValue}
					/>	

					<FormTextField
						as={Col}
						md="6"
						controlId="validationFormikAgencyTelephone"
						label="Telephone Number"
						type="text"
						name="agency_contact_telephone_number"
						maxLength={24}
						setFieldValue={setFieldValue}
						onChange={
								setTimeout(()=> {
									validateTelephone()
								}, 500)
							}
					/>
				</Fragment>
				:
				null
			}	
				
		
		</AnimatedRow>
	);
};
export default StepOne;