import React, { Suspense, Fragment, createRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import * as EnrollmentAPI from "../EnrollmentAPI";
import * as ApiErrorHandler from "./ApiErrorHandler";
import { createBrowserHistory as createHistory } from "history";


import { Formik } from "formik";
import { stepOneSchema, stepTwoSchema, stepThreeSchema, stepFourSchema } from './yupSchema'
import { headline } from '../utils/confirmationMessage'

import FormStepOne from "./FormStepOne";
const FormStepTwo = React.lazy(() => import('./FormStepTwo'));
const FormStepThree = React.lazy(() => import('./FormStepThree'));
const FormStepFour = React.lazy(() => import('./FormStepFour'));
const RegistrationCompletedPromise = import(/* webpackChunkName: 'RegistrationCompleted' */ "./RegistrationCompleted");
const RegistrationCompleted = React.lazy(() => RegistrationCompletedPromise);

let wizardSchema;

const formInitial = {
  ori: "",
  authorized_applicant_type: "",
  type_of_licence: "",
  agency_name: "",
  agency_mail_code: "",
  agency_street_number: "",
  agency_street_direction: "",
  agency_street_name: "",
  agency_contact_name: "",
  agency_city: "",
  agency_state: "",
  agency_zip_code: "",
  agency_contact_telephone_number: "",
  applicant_last_name: "",
  applicant_first_name: "",
  applicant_middle_initial: "",
  applicant_suffix: "",
  applicant_other_last_name: "",
  applicant_other_first_name: "",
  applicant_other_suffix: "",
  applicant_date_of_birth: '',
  applicant_sex: "",
  applicant_height: "",
  applicant_weight: "",
  applicant_eye_colour: "",
  applicant_hair_colour: "",
  applicant_social_security_number: "",
  applicant_house_number: "",
  applicant_street_direction: "",
  applicant_street_name: "",
  applicant_apartment_number: "",
  applicant_driver_licence_number: "",
  applicant_billing_number: "",
  applicant_city: "",
  applicant_state: "",
  applicant_zip_code: "",
  oca: "",
  level_of_service: [],
  original_ati: "",
  employer_name: "",
  employer_mail_code: "",
  employer_street_number: "",
  employer_street_direction: "",
  employer_street_name: "",
  employer_city: "",
  employer_state: "",
  employer_zip_code: "",
  employer_telephone_number: "",
  pay_by_credit: true,
  should_pay: true,
  api_has_fees: false,
  card_number: "",
  cardholder_name: "",
  credit_expiry: null,
  security_code: "",
  billing_street_address: "",
  billing_street_address2: "",
  billing_country: "US",
  billing_city: "",
  billing_state: "",
  billing_zip: "",
  applicant_account_number: "",
  applicant_account_number_verified: "",
  token_notification_method: ["E-mail"],
  receieve_email: true,
  receieve_sms: false,
  applicant_email: "",
  applicant_phone_number: "",
  account_agreement: false, 
}

let initialValues;


const stepsFields = [
  [
    "ori",
    "authorized_applicant_type",
    "type_of_licence",
    "agency_name",
    "agency_mail_code",
    "agency_street_number",
    "agency_street_name",
    "agency_contact_name",
    "agency_city",
    "agency_state",
    "agency_zip_code",
    "agency_contact_telephone_number"
  ],
  [
    "applicant_first_name",
    "applicant_last_name",
    "applicant_middle_initial",
    "applicant_suffix",
    "applicant_other_last_name",
    "applicant_other_first_name",
    "applicant_other_suffix",
    "applicant_date_of_birth",
    "applicant_sex",
    "applicant_height",
    "applicant_weight",
    "applicant_eye_colour",
    "applicant_hair_colour",
    "applicant_place_of_birth",
    "applicant_social_security_number",
    "applicant_home_address",
    "applicant_house_number",
    "applicant_street_direction",
    "applicant_street_name",
    "applicant_apartment_number",
    "applicant_driver_licence_number",
    "applicant_billing_number",
    "applicant_misc_number",
    "applicant_city",
    "applicant_state",
    "applicant_zip_code"
  ],
  [
    "oca",
    "level_of_service",
    "original_ati",
    "employer_name",
    "employer_mail_code",
    "employer_street_number",
    "employer_street_direction",
    "employer_street_name",
    "employer_city",
    "employer_state",
    "employer_zip_code",
    "employer_telephone_number"
  ],
  [
    "card_number",
    "cardholder_name",
    "credit_expiry",
    "expiration_month",
    "expiration_year",
    "security_code",
    "billing_street_address",
    "billing_street_address2",
    "billing_country",
    "billing_city",
    "billing_state",
    "billing_zip",
    "applicant_account_number",
    "applicant_email",
    "applicant_phone_number",
    "account_agreement",
  ]
]
export const getFieldStepIndex = fieldName => {
  return stepsFields.filter(step=> step.includes(fieldName)).map(step=>stepsFields.indexOf(step))[0]
}
class WizardForm extends React.Component {
  constructor(props) {
    super(props);
    initialValues = formInitial;
    wizardSchema = [stepOneSchema, stepTwoSchema, stepThreeSchema, stepFourSchema]

    this.state = {
      apiRequesting: false,
      page: 0,
      arrayProgress : [
        {
          title: "Submission & Contributing Agency Info",
          completed: false
        },
        {
          title: "Applicant Info",
          completed: false
        },
        {
          title: "Transaction Info & Employer",
          completed: false
        },
        {
          title: "Payment",
          completed: false
        }
      ],
      regIsCompleted : false,
      orderDetails: {
        referenceId: "458",
        referenceToken: "WmxS7DHN",
        userSubmissionDate: "24/01/2020",
        userCompletionDate: "09/02/2020",
        firstName: "John",
        lastName: "Doe"
      },
      refCode: this.props.refCode,
      value: {},
      errors: [],
      formRef: createRef(),
      levelOfService: 4,
      LOSOptions: ["DOJ", "FBI"],
      LOSCheckedDisabled: [],
      stateFBICA: '',
      aliasDisplayed: false,
      streetSectionDisplayed: false,
      heightWeightSectionDisplayed: false,
      employerSectionDisplayed: false,
      fbiSelected: false,
      paymentMethod: 'credit-card',
      authListPopulated: [],
      authListPopulatedSelected: '',
      payTraceError: false,
      payTraceErrorMsg: "",
      applicant_date_of_birth: "",
      applicant_email: "",
      applicant_phone_number: "",
      is_agreement_required: false,
      is_agreement_checked: false
    }
    this.wizardSchema = wizardSchema;
    this.nextStep = this.nextStep.bind(this);
    this.nextStepHandler = this.nextStepHandler.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.handleDisplayAlias = this.handleDisplayAlias.bind(this);
    this.handleDisplayStreetSection = this.handleDisplayStreetSection.bind(this);
    this.handleDisplayHeightWeightSection = this.handleDisplayHeightWeightSection.bind(this);
    this.handleDisplayEmployer = this.handleDisplayEmployer.bind(this);
    this.handleAuthListPopulated = this.handleAuthListPopulated.bind(this);
    this.handleAuthListPopulatedSelected = this.handleAuthListPopulatedSelected.bind(this);
    this.handleLOSChanged = this.handleLOSChanged.bind(this);
    this.fbiSelectedHandler = this.fbiSelectedHandler.bind(this);
    this.paymentMethodHandler = this.paymentMethodHandler.bind(this);
    this.isAgreementRequiredHandler = this.isAgreementRequiredHandler.bind(this);
    this.isAgreementCheckedHandler = this.isAgreementCheckedHandler.bind(this);

  }

  async nextStepHandler(values, setFieldError) {
    const billingNum = values.applicant_billing_number
    const ori = values.ori

    if (this.state.page === 1 && billingNum.length ) {

      try {
        const response = await EnrollmentAPI.verifyBillingNumWithOri(billingNum, ori);
        if (response.status === 400) {
          setFieldError('applicant_billing_number', "Invalid billing number")
        } else if (response.status === 404) {
          setFieldError('applicant_billing_number', "Billing number not found")
        } else {
          this.nextStep()
        }
      } catch (error) {
        //TODO Show Generic Error Message to User
        console.log(error)
      }

    } else if ( 
      this.state.page === 2 
      && this.state.LOSOptions.includes('FBI') 
      && !values.level_of_service.includes('FBI') ) {
      
      if ( this.confirmFbiNotSelected() ) {
        this.nextStep()  
      }
      return;
    } else {
      this.nextStep()
    }
  }

  nextStep() {
    this.setState(prevState=> ({
      page: prevState.page <= 3 ? prevState.page + 1 : 4,
      arrayProgress: prevState.arrayProgress.map(
        (el,index) => index === prevState.page? { ...el, completed: true }: el
      )
    }))
  }
  
  prevStep() {
    this.setState(prevState=>({
      page: prevState.page > 0 ? prevState.page - 1 : 0,
      arrayProgress: prevState.arrayProgress.map(
        (el,index) => index === prevState.page? { ...el, completed: false }: el
      )
    }));
  }

  async createOrder(values, actions) { 
    this.setState({
      applicant_date_of_birth: values.applicant_date_of_birth,
      applicant_email: values.applicant_email,
      applicant_phone_number: values.applicant_phone_number
    })
    try {
      this.setState({apiRequesting: true});
      const response = await EnrollmentAPI.createOrder(values, this.state.refCode);
      this.setState({apiRequesting: false});
      const responseChecked = await ApiErrorHandler.apiCheckErrors.call(this, response, actions);
      const jsonResponse = await responseChecked.json();
      
      //TODO Show Success View
      this.setState({
        regIsCompleted: true,
        orderDetails: {
          referenceId: jsonResponse.data.id,
          referenceToken: jsonResponse.data.token,
          firstName: jsonResponse.data.applicant_first_name,
          lastName: jsonResponse.data.applicant_last_name,
          fees: jsonResponse.data.fee,
          userSubmissionDate: jsonResponse.data.created_at
        }
      });

    } catch (error) {
      //TODO Show Generic Error Message to User
      console.log(error)
    }
    
  }

  // Handle Level Of Service Changed
  handleLOSChanged(levelOfService = this.state.levelOfService, setFieldValue) {
    let tempLOSCheckedDisabled = []
    let tempLOSOptions = []
    // Level Of Service Logic
    switch(levelOfService) {
      case "1":
        tempLOSCheckedDisabled = ["DOJ"]
        tempLOSOptions = ["DOJ"]
        break
      case "2":
        tempLOSCheckedDisabled = ["FBI"]
        tempLOSOptions = ["FBI"]
        break
      case "3":
        tempLOSCheckedDisabled = ["DOJ", "FBI"]
        tempLOSOptions = ["DOJ", "FBI"]
        break
      case "4":
        tempLOSCheckedDisabled = ["DOJ"]
        tempLOSOptions = ["DOJ", "FBI"]
        break
      default:
        tempLOSCheckedDisabled = []
        tempLOSOptions = ["DOJ", "FBI"]
    }

    this.setState({
      levelOfService,
      LOSOptions: tempLOSOptions,
      LOSCheckedDisabled: tempLOSCheckedDisabled,
      stateFBICA: levelOfService
    })
    setFieldValue('level_of_service', tempLOSCheckedDisabled)
  }

  handleDisplayAlias() {
    this.setState({
      aliasDisplayed: true
    })
  }

  handleDisplayStreetSection() {
    this.setState({
      streetSectionDisplayed: true
    })
  }

  handleDisplayHeightWeightSection() {
    this.setState({
      heightWeightSectionDisplayed: true
    })
  }

  handleDisplayEmployer() {
    this.setState({
      employerSectionDisplayed: true
    })
  }

  handleAuthListPopulated(list) {
    this.setState({
      authListPopulated: list
    })
  }

  handleAuthListPopulatedSelected(value) {
    this.setState({
      authPopulatedListSelected: value
    })
  }

  fbiSelectedHandler() {
    this.setState({
      fbiSelected: true
    })
  }

  confirmFbiNotSelected() {
    const isConfirmed = window.confirm(`You have left the FBI unchecked.
Please review the Live Scan Request Form for the required level of service.
Continue to next step?`);
    return isConfirmed;
  }

  paymentMethodHandler(selected) {
    this.setState({
      paymentMethod: selected
    })
  }
  isAgreementRequiredHandler(value){
    this.setState({is_agreement_required: value})
  }
  isAgreementCheckedHandler(value){
    this.setState({is_agreement_checked: value})
  }
  history = createHistory(this.props);
  render() {
    
    const currentStep = this.state.page;
    const { 
      handleDisplayAlias, 
      handleDisplayStreetSection, 
      handleDisplayHeightWeightSection,
      handleDisplayEmployer,
      handleLOSChanged,
      fbiSelectedHandler,
      paymentMethodHandler,
      handleAuthListPopulated,
      handleAuthListPopulatedSelected,
      isAgreementRequiredHandler,
      isAgreementCheckedHandler,
      history
    } = this;
    
    const {
      arrayProgress, 
      regIsCompleted, 
      apiRequesting,
      refCode, 
      formRef, 
      aliasDisplayed, 
      streetSectionDisplayed, 
      heightWeightSectionDisplayed, 
      employerSectionDisplayed,
      LOSCheckedDisabled,
      LOSOptions,
      stateFBICA,
      fbiSelected,
      paymentMethod,
      authListPopulated,
      authListPopulatedSelected,
      payTraceError,
      payTraceErrorMsg,
      applicant_date_of_birth,
      applicant_email,
      is_agreement_required,
      is_agreement_checked,
    } = this.state

    const {
      referenceId,
      referenceToken,
      firstName,
      lastName,
      fees,
      userSubmissionDate,
    } = this.state.orderDetails
    return (
        
        regIsCompleted ?
          <Row className="box-wrapper mt-4 mb-4 pt-4 pb-4">
            <Suspense fallback={
            <div className={`circle-loader`}>
              <div className={`checkmark draw`}></div>
            </div>
            }>
              <RegistrationCompleted
                refId={referenceId}
                refToken={referenceToken}
                applicant_date_of_birth={applicant_date_of_birth}
                applicant_email={applicant_email}
                firstName={firstName}
                lastName={lastName}
                fees={fees}
                submissionDate={userSubmissionDate}
                history={history}
              />
            </Suspense>
          </Row>
        :
          <Fragment>
            <Row className="form-title-row">
              <Col>
                <h1 className="form-title text-center prime-color mt-4">{headline()}</h1>
              </Col>
            </Row>
            <Row className="box-wrapper mt-4 mb-4 pt-4 pb-4" ref={formRef}>
            
              <Col md={{ span: 12 }} lg={{ span: 10, offset: 1 }}>
                <p className="text-center mt-4 mb-4">Step {currentStep + 1} of {arrayProgress.length}</p>
                <ol className="c-progress-steps">
                {arrayProgress.map((item, index) => {
                  return (
                    <li key={item.title}
                      className={`c-progress-steps__step ${currentStep === index ? "current" : ""} ${this.state.arrayProgress[index].completed ? "done" : ""}`}>
                      <span>{item.title}</span>
                    </li>
                  );
                })}
                </ol>
                
              </Col>
              <Col md={{ span: 12 }} lg={{ span: 10, offset: 1 }} className="pb-4">
                <Formik
                  validationSchema={wizardSchema[currentStep]}
                  onSubmit={(values, actions)=> this.createOrder(values, actions)}
                  initialValues={initialValues}
                >
                  {({
                    dirty,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    validateField,
                    values,
                    errors,
                    isValid,
                    isSubmitting,
                    touched,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched
                  }) => (
                    <Form id="cls-enroll-form" noValidate onSubmit={handleSubmit}>
                        {(function() {
                          
                          switch (currentStep) {
                            case 0:
                              return (
                              <Suspense fallback={<div className="text-center"><div className={`circle-loader`}>
                                  <div className={`checkmark draw`}></div>
                                </div></div>}>
                              <FormStepOne 
                                setFieldValue={setFieldValue} 
                                setFieldError={setFieldError} 
                                elRef={formRef} 
                                values={values} 
                                touched={touched} 
                                onShowStreet={handleDisplayStreetSection} 
                                streetSectionDisplayed={streetSectionDisplayed} 
                                errors={errors} 
                                onChange={handleChange} 
                                setField={setFieldValue} 
                                onFbCaChanged={(val)=>handleLOSChanged(val, setFieldValue)}
                                stateFBICA={stateFBICA}
                                onAuthListPopulated={handleAuthListPopulated}
                                onAuthListPopulatedSelected={handleAuthListPopulatedSelected}
                                authListPopulated={authListPopulated}
                                authListPopulatedSelected={authListPopulatedSelected}
                              />
                            </Suspense>
                            );
                            case 1:
                              return (
                                <Suspense fallback={<div className="text-center"><div className={`circle-loader`}>
                                  <div className={`checkmark draw`}></div>
                                </div></div>}>
                                  <FormStepTwo 
                                  setFieldError={setFieldError} 
                                  elRef={formRef} 
                                  errors={errors} 
                                  values={values}
                                  touched={touched} 
                                  onChange={handleChange} 
                                  onShowAlias={handleDisplayAlias} 
                                  aliasDisplayed={aliasDisplayed} 
                                  onShowHeightWeight={handleDisplayHeightWeightSection} 
                                  heightWeightSectionDisplayed={heightWeightSectionDisplayed}
                                  setFieldValue={setFieldValue}
                                />
                                </Suspense>
                                );
                            case 2:
                              return (
                                <Suspense fallback={<div className="text-center"><div className={`circle-loader`}>
                                  <div className={`checkmark draw`}></div>
                                </div></div>}>
                                  <FormStepThree 
                                  setFieldError={setFieldError} 
                                  errors={errors} 
                                  stepSchema={wizardSchema[currentStep]} 
                                  elRef={formRef} 
                                  initValues={initialValues} 
                                  values={values} 
                                  onChange={handleChange} 
                                  setFieldValue={setFieldValue}
                                  onShowEmployer={handleDisplayEmployer}
                                  employerSectionDisplayed={employerSectionDisplayed}
                                  LOSOptions={LOSOptions}
                                  LOSCheckedDisabled={LOSCheckedDisabled}
                                  touched={touched} 
                                  fbiSelected={fbiSelected}
                                  onFBISelected={fbiSelectedHandler}
                                  setFieldTouched={setFieldTouched}
                                />
                                </Suspense>
                                );
                            case 3:
                              return (
                                <Suspense fallback={<div className="text-center"><div className={`circle-loader`}>
                                  <div className={`checkmark draw`}></div>
                                </div></div>}>

                                  <FormStepFour 
                                  setFieldError={setFieldError} 
                                  errors={errors} 
                                  stepSchema={wizardSchema[currentStep]} 
                                  elRef={formRef} 
                                  initValues={initialValues} 
                                  values={values} 
                                  onChange={handleChange} 
                                  setFieldValue={setFieldValue}
                                  touched={touched} 
                                  paymentMethod={paymentMethod}
                                  setPaymentMethod={paymentMethodHandler}
                                  payTraceError={payTraceError}
                                  payTraceErrorMsg={payTraceErrorMsg}
                                  refCode={refCode}
                                  isAgreementRequired = {is_agreement_required}
                                  setAgreementRequired = {isAgreementRequiredHandler}
                                  isAgreementChecked = {is_agreement_checked}
                                  setAgreementChecked= {isAgreementCheckedHandler}
                                />
                                </Suspense>
                                );    
                            default:
                              return <FormStepOne elRef={formRef} onChange={handleChange} setFieldError={setFieldError} setField={setFieldValue} />;
                          }
                        })()}
                      <Row className="mt-5">
                        <Col>
                          {
                            currentStep !== 0 ? 
                            <Button 
                            className="mb-3"
                            variant="outline-primary"
                            onClick={this.prevStep}>
                              {currentStep === 4 ? "Edit":"Back"}
                            </Button>
                            : null  
                          }
                       
                          {
                            currentStep !== 3 ? 
                          <Button
                          disabled={
                            (currentStep === 0 && Object.keys(touched).length === 0 && touched.constructor === Object)
                            || !Object.keys(wizardSchema[currentStep].fields).some(field=> touched && touched[field] !== undefined) 
                            || Object.keys(wizardSchema[currentStep].fields).some(field=> errors[field] !== undefined)
                          }
                          onClick={()=>this.nextStepHandler(values, setFieldError)}>
                            Next
                          </Button>
                            : null  
                          }

                          {
                            apiRequesting ?
                            
                              <div className="circle-loader api-requesting">
                              </div>
                            
                            :null
                          }

                          {currentStep === 3 ?
                            <Button
                              disabled={ !isValid || isSubmitting 
                                || (values.should_pay === true && values.pay_by_credit === false  && (values.applicant_account_number_verified === "true" && is_agreement_required && !is_agreement_checked))
                                || (values.should_pay === true && values.pay_by_credit === false && values.applicant_account_number_verified !== "true" )
                                || (values.should_pay === false && values.api_has_fees === false && values.applicant_account_number.length && values.applicant_account_number_verified !== "true" )
                              }
                              variant="success"
                              size="lg"
                              type="submit"
                              value="Submit">
                              Submit
                            </Button> :
                            null
                          }
                          
                        </Col>
                        
                      </Row>
                      
                      {/* <Row>
                        <Col>
                          <pre style={{ margin: "0 auto" }}>
                            {JSON.stringify(
                              { ...errors,k:'======================>', 
                                touched: {
                                ...touched
                                } 
                              },
                              null,
                              2
                            )}
                            {JSON.stringify(
                              { 
                                ...values, 
                                // ...errors, 
                                isValid, 
                                // isSubmitting 
                              },
                              null,
                              2
                            )}
                          </pre>
                        </Col>
                      </Row> */}
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Fragment>
          
    );
  }
}

export default WizardForm;