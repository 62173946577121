import React, { useRef, Fragment } from "react";
import ReactDOM from "react-dom";
import { Form, InputGroup } from "react-bootstrap";
import { Field } from "formik";
import { _debounce } from "../../utils/helper";

const FormTextField = ({
	as,
	md,
	sm,
	xs,
	controlId,
	label,
	optional,
	name,
	type,
	inputGroupPrepend,
	inputGroupAppend,
	placeHolder,
	autoFill,
	minLength,
	maxLength,
	customClass,
	hint,
	hintMessage,
	hintLetter,
	autoComplete,
	autoCompleteData,
	listOpened,
	setFieldValue,
	delayedChanged,
	delaySleep,
	disabled,
	customInValid,
	customInValidMsg
}) => {
	const labelHintEl = useRef(null);
	const hintFormGroup = useRef(null);

	const delayedInputChanged = useRef(_debounce(delayedChanged, delaySleep = 300)).current

	const showHint = (e) => {
		const hintInputGroup = hintFormGroup.current;
		const node = ReactDOM.findDOMNode(hintInputGroup)
		node.classList.toggle('hint-active');
		e.stopPropagation();
	};
	
	return (
		<Field
			name={name}>
			{({ field, form }) => {
				const isValid = customInValid ? (!form.errors[field.name] && !customInValid) : !form.errors[field.name];
				const isInvalid = customInValid ? (form.touched[field.name] && !isValid && customInValid) : form.touched[field.name] && !isValid;
				let customClassVal = customClass ? customClass : '';
				customClassVal += autoComplete ? 'div-relative' : '';
				return (
					<Form.Group 
					className={customClassVal}
					as={as} md={md} sm={sm} xs={xs} controlId={controlId} ref={hintFormGroup}>
						{label ?
							<Fragment>
							<Form.Label>{label} {optional ? <span className="optional-field"><i>(Optional)</i></span> : null} </Form.Label>
							{hint ? 
								<div className="label-hint-wrapper">
									<div className="label-hint" ref={labelHintEl} onClick={showHint}>
										<span>{hintLetter ? hintLetter : "?"}</span>
									</div> 
								</div>
								: null}
							</Fragment>
						: null
						}
						{ hint ?
							<div className="label-hint-message">{hintMessage}</div>
							:
							null
						}
						<InputGroup 
							className={
								(inputGroupAppend || inputGroupPrepend) ? "input-has-icon" : ""
								}
							>
							{ 
								inputGroupPrepend ?

								<InputGroup.Prepend>
									<InputGroup.Text id="inputGroupPrepend">{inputGroupPrepend}</InputGroup.Text>
								</InputGroup.Prepend>
								: null
							}
							<Form.Control
								{...field}
								autoComplete={autoFill}
								placeholder={placeHolder}
								minLength={minLength}
								maxLength={maxLength}
								onInput = {(e) =>{
									e.target.value = maxLength ? e.target.value.toString().slice(0,parseInt(maxLength)) : e.target.value									
									//auto uppercase test
									e.target.value = e.target.value.toString().toUpperCase()

									if (name.includes('zip')) {
										const regex = /\b\d{5}/;
										const found = 	e.target.value.match(regex);
									
										if (found && e.target.value.length > 5) {
											var foo = e.target.value.toString().split("-").join(""); // remove hyphens
											e.target.value = [...foo.slice(0, 5), '-', ...foo.slice(5)].join('');
										}

										if ( found && e.target.value.length === 6 & (e.target.value.toString()).includes('-') ) {
											e.target.value = e.target.value.toString().split("-").join("");
										}
									}
									if ( name === 'applicant_social_security_number') {
										e.target.value = e.target.value.toString().split("-").join(""); // remove hyphens
									}

								}}
								
								onChange={(e)=>{
									setFieldValue(name, e.target.value)
									delayedChanged && delayedInputChanged(e.target.value)
								}}

								type={type}
								isValid={form.touched[field.name] && field.value.length && isValid}
								isInvalid={isInvalid}
								feedback={form.errors[field.name]}
								bsPrefix="form-control data-hj-allow"
								disabled={disabled}
							/>

							{inputGroupAppend}
							<Form.Control.Feedback type="invalid">
								{field.value.length && customInValidMsg ? customInValidMsg : form.errors[field.name]}
							</Form.Control.Feedback>
							
						</InputGroup>
						{
							autoComplete && autoCompleteData && listOpened ?
							<div className="autocomplete-list">
								<ul className={listOpened ? `auto-list-open` : `d-none`}>
									{autoCompleteData}
								</ul>
							</div>
							:
							null
						}
					</Form.Group>
				);
			}}
		</Field>
	);
};

FormTextField.defaultProps = {
	type: "text",
	inputGroupPrepend: null
};

export default FormTextField;
