import * as yup from 'yup'

/* eslint-disable no-useless-escape */
export const stepOneSchema = yup.object().shape({
  ori: yup.string().matches(/^[a-zA-Z0-9]*$/, { message: "5 - 9 alphanumeric field, empty value not allowed"}).required('Required Field').test('len', '5 - 9 alphanumeric characters', 
  val => {
    return val && val.toString().length < 10 && val.toString().length > 4
  }),
  authorized_applicant_type: yup.string().matches(/^(?!\s*$)[A-Za-z][A-Z-a-z,-=.\/();:'&$+\s]+$/, { message: "Allowed special characters ( ,-=./();:'&$+ ), empty value not allowed"}).required('Required Field')
    .test('len', '1 - 25 alphanumeric/special characters', 
    val => {
    return val && val.toString().length < 26
  }),
  type_of_licence: yup.string().matches(/^(?!\s*$)[/\w\s]*$/, { message: "Allowed special character ( / ), empty value not allowed"}).required('Required Field')
    .test('len', '1 - 30 alphanumeric/special characters', 
    val => {
    return val && val.toString().length < 31
  }),
  agency_name: yup.string().matches(/^[-\/[\]'&A-Za-z\s]{5,25}$/, { message: "5 - 25 characters, allowed special characters ( &-/'[] )"}),
  agency_mail_code: yup.string().matches(/^([0-9]{5})$/, { message: "5 characters numeric field"}),
  agency_street_number: yup.string().matches(/^[/A-Za-z0-9]{1,10}$/, { message: "Max 10 characters, allowed special character ( / )"}),
  agency_street_direction: yup.string(),
  agency_street_name: yup.string().matches(/^[-,'()\w\s]{1,20}$/, { message: "Max 20 characters, allowed special characters ( ,'- () )"}),
  agency_city: yup.string().matches(/^[,-./#;:'&$+\w\s]{1,25}$/, { message: "Max 25 characters, allowed special characters ( ,-\./#;:'&$ )"}),
  agency_state: yup.string(),
  agency_zip_code: yup.string().matches(/^[0-9]{5}([- /]?[0-9]{4})?$/, { message: "A valid zip code is 5 digits, you can add a hyphen and a 4-digit extension"}),
  agency_contact_name: yup.string(),
  agency_contact_telephone_number: yup.string().matches(/^[-,()#+0-9]{5,24}$/, { message: "5 - 24 characters, allowed special characters ( -,()#+ )"})
});

export const stepTwoSchema = yup.object().shape({
  applicant_first_name: yup.string().typeError('Letters only allowed.').matches(/^(?!\s*$)[A-z*a-z]*$/, { message: "Allowed special character ( * ), empty value not allowed"}).required('Required Field')
  .test('len', 'Max 16 characters Or *.', 
  val => {
    if (val && val.toString().includes('*')) {
      return val.toString().length < 2
    }
    return val && val.toString().length < 17
  }),
  applicant_last_name: yup.string().typeError('Letters only allowed.').matches(/^(?=^.{1,16}$)[a-z]+(?:[\- ]{0,1}[a-z]+){0,3}$/mi, { message: "Letters only, (-) is only allowed to be preceded and followed by letters, empty value not allowed"}).required('Required Field')
  .test('len', 'Max 16 characters', 
  val => {
    return val && val.toString().length < 17
  }),
  applicant_middle_initial: yup.string().matches(/^[a-zA-Z]{1}$/, { message: "Single Letter"}),
  applicant_suffix: yup.string().matches(/^(\bJR|\bSR|[I,V,X]{1,3})$/, { message: "1 - 3 alpha characters"}),
  applicant_other_last_name: yup.string(),
  applicant_other_first_name: yup.string().matches(/^[A-z*a-z]*$/, { message: "Allowed special character ( * ), empty value not allowed"})
  .when('applicant_other_last_name', {
    is: role => role && role.length > 0,
    then: yup.string().required('Required Field')
  }),
  applicant_other_suffix: yup.string().matches(/^(\bJR|\bSR|[I,V,X]{1,3})$/, { message: "1 - 3 alpha characters"}),
  applicant_date_of_birth: yup.mixed().required('Required Field'),
  applicant_sex: yup.string().required('Required Field'),
  applicant_height: yup.string().matches(/^((000)|([2-7]{1,1}[\']{0,1}[ ]{0,1}(([0-9]{1,2})|(0[0-9]{1,1})|(1[0-1]{1,1}))[\"]{0,1})|([8]{1,1}[\']{0,1}[ ]{0,1}(([0-9]{1,1})|(0[0-9]{1,1})|(1[0-1]{1,1}))[\"]{0,1}))$/, { message: "Height must be written as a 3-digit value between 200 and 811"}),
  applicant_weight: yup.string().matches(/^0*([3-8][0-9]|9[0-9]|[1-5][0-9]{2}|6[0-8][0-9]|69[0-9])$/, { message: "Weight must be written as a 3-digit value between 030 and 699"}),
  applicant_eye_colour: yup.string(),
  applicant_hair_colour: yup.string(),
  applicant_social_security_number: yup.string().matches(/^(?!000000000)(?!999999999)(?!123456789)[0-9]{9}$/, { message: "9 character numeric field"}),
  applicant_house_number: yup.string().matches(/^(?=^.{1,10}$)[a-z0-9]+(?:[\s]){0,1}(?:[0-9][\/][0-9]){0,1}$/, { message: "Allowed special character ( / ), empty value not allowed"}).required('Required Field')
  .test('len', '1 - 10 alphanumeric/special characters',
    val => {
    return val && val.toString().length < 11
  }),
  applicant_street_direction: yup.string(),
  applicant_street_name: yup.string().matches(/^(?!\s*$)[-,'()\w\s]{1,20}$/, { message: "Max 20 characters, allowed special character ( ,'- () ), empty value not allowed"}).required('Required Field'),
  applicant_apartment_number: yup.string().matches(/^(?!\s*$)[-#,\w\s]{1,5}$/, { message: "Allowed special characters (,-#), empty value not allowed"}),
  applicant_driver_licence_number: yup.string().matches(/^(?!C1112804)(?!C1212804)(?!C1312804)(?!C1414804)(?:(?![IiLlOoQqTtXx])[A-Za-z]){1}[0-9]{7}$/, { message: "8 character alphanumeric field"}),
  applicant_billing_number: yup.string().matches(/^(?!000000)([0-9]{6})$/, { message: "6 characters numeric field, all zeros not allowed."}),
  applicant_city: yup.string().matches(/^[,-./#;:'&$+\w\s]{1,25}$/, { message: "Max 25 characters, allowed special characters ( ,-\./#;:'&$ )"}),
  applicant_state: yup.string(),
  applicant_zip_code: yup.string().matches(/^\d{5}(?:[-]\d{4})?$/, { message: "A valid zip code is 5 digits, you can add a hyphen and a 4-digit extension"})
});

export const stepThreeSchema = yup.object().shape({
  oca: yup.string().matches(/^[\w\s]*$/, { message: "Alphanumeric"}),
  level_of_service: yup.array().min(1, "At Least one must be checked"),
  original_ati: yup.string().matches(/^[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{3}[0-9]{3}$/, { message: "10 character alphanumeric field"}),
  employer_name: yup.string().when(['employer_mail_code', 'employer_telephone_number'], {
    is: (b, c) => b || c,
    then: yup.string().required('Required Field')
  }),
  employer_mail_code: yup.string().matches(/^([0-9]{5})$/, { message: "5 characters numeric field"}),
  employer_street_number: yup.string().matches(/^(?!\s*$)[/A-Za-z0-9]{1,10}$/, { message: "1-10 characters, Allowed special character ( / ), empty value not allowed"})
  .when(['employer_name', 'employer_mail_code'], {
    is: (b, c) => b || c,
    then: yup.string().required('Required Field')
  }),
  employer_street_direction: yup.string(),
  employer_street_name: yup.string().matches(/^(?!\s*$)[-,'()\w\s]{1,20}$/, { message: "Max 20 characters, allowed special characters ( ,'- () ), empty value not allowed"})
    .when(['employer_name', 'employer_street_number'], {
      is: (b, c) => b || c,
      then: yup.string().required('Required Field')
    }
  ),
  employer_city: yup.string().matches(/^(?!\s*$)[,-./#;:'&$+\w\s]{1,25}$/, { message: "Max 25 characters, allowed special characters ( ,-\./#;:'&$ ), empty value not allowed"})
  .when(['employer_name', 'employer_street_number', 'employer_mail_code'], {
    is: (b, c, d) => b || c || d,
    then: yup.string().required('Required Field')
  }),
  employer_state: yup.string().when(['employer_name', 'employer_street_number', 'employer_city'], {
    is: (b, c, d) => b || c || d,
    then: yup.string().required('Required Field')
  }),
  employer_zip_code: yup.string().matches(/^[0-9]{5}([- /]?[0-9]{4})?$/, { message: "A valid zip code is 5 digits, you can add a hyphen and a 4-digit extension"})
  .when(['employer_name', 'employer_street_number', 'employer_city'], {
    is: (b, c, d) => b || c || d,
    then: yup.string().required('Required Field')
  }),
  employer_telephone_number: yup.string().matches(/^[-,()#+0-9]{5,24}$/, { message: "5 - 24 characters, allowed special characters ( -,()#+ )"}),
}, [['employer_name', 'employer_mail_code'], ['employer_name', 'employer_telephone_number'], ['employer_mail_code','employer_telephone_number']]);


export const stepFourSchema = yup.object().shape({
  should_pay: yup.boolean(),
  api_has_fees: yup.boolean(),
  pay_by_credit: yup.boolean(),
  card_number: yup.string()
  .when('pay_by_credit', {
    is: true,
    then: yup.string().matches(/^[0-9]{15,16}$/, { message: "A valid credit card is 15 ~ 16 digits."}).required('Required Field')
  }),
  cardholder_name: yup.string()
  .when('pay_by_credit', {
    is: true,
    then: yup.string().required('Required Field')
  }),
  credit_expiry: yup.mixed()
  .when('pay_by_credit', {
    is: true,
    then: yup.mixed().required('Required Field')
  }),
  security_code: yup.string()
  .when('pay_by_credit', {
    is: true,
    then: yup.string().matches(/^[0-9]{3,4}$/, { message: "A valid security code is 3 ~ 4 digits."}).required('Required Field')
  }),
  billing_street_address: yup.string()
  .when('pay_by_credit', {
    is: true,
    then: yup.string().required('Required Field')
  }),
  billing_street_address2: yup.string(),
  billing_country: yup.string(),
  billing_city: yup.string(),
  billing_state: yup.string(),
  billing_zip: yup.string()
  .when('pay_by_credit', {
    is: true,
    then: yup.string().matches(/^[0-9]{5}([- /]?[0-9]{4})?$/, { message: "A valid zip code is 5 digits, you can add a hyphen and a 4-digit extension"}).required('Required Field')
  }),
  applicant_account_number: yup.string()
  // .when('pay_by_credit', {
  //   is: false,
  //   then: yup.string().required('Required Field')
  // }),
  .when(['pay_by_credit', 'should_pay'], {
    is: (a, b) => !a && b,
    then: yup.string().required('Required Field')
  }),
  applicant_account_number_verified: yup.boolean(),
  receieve_email: yup.boolean(),
  receieve_sms: yup.boolean(),
  applicant_email: yup.string()
  .when('receieve_email', {
    is: true,
    then: yup.string().email('Must be a valid email').required('Required Field')
  }),
  applicant_phone_number: yup.string()
  .when('receieve_sms', {
    is: true,
    then: yup.string().matches(/^[0-9]{10}$/, { message: "Must be a valid phone number."}).required('Required Field')
  }),
});

