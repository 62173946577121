import paytrace from './utils/paytrace/paytrace-e2ee';

const apiURL = process.env.REACT_APP_API_URL

// Using Public Key provided by Menna
paytrace.setKey();

/**
* Create Order Request
* @param {JSON} query 
*/
export const createOrder = (query, refCode) => {
  let expiration_month = '';
  let expiration_year = '';

  let { 
    applicant_height, 
    applicant_date_of_birth,
    level_of_service,
    pay_by_credit,
    should_pay,
    api_has_fees,
    card_number,
    security_code,
    credit_expiry,
    applicant_account_number_verified,
    token_notification_method,
    receieve_email,
    receieve_sms,
    ...tempQuery } = query
  
  if (card_number.length) {
    expiration_month = (credit_expiry.getMonth() + 1).toString();
    expiration_year = credit_expiry.getFullYear().toString().substr(-2);
  }

  applicant_height = applicant_height.replace("'", '').replace('"', '').replace(' ', '');
  // Append 0 after first digit in case input was e.g. 5' 9" to convert it to 509
  applicant_height = applicant_height.length === 2 ? `${applicant_height[0]}0${applicant_height[1]}` : applicant_height;
  const date = new Date(applicant_date_of_birth);
  const year = date.getFullYear();
  let month = date.getMonth()+1;
  let day = date.getDate();

  applicant_date_of_birth = `${year}-${month}-${day}`

  let level_of_service_doj = level_of_service.length && level_of_service.includes('DOJ') ? true : false;
  let level_of_service_fbi = level_of_service.length && level_of_service.includes('FBI') ? true : false;
  let card_first_number = null;
  let card_type = null;
  
  if (card_number.length) {
    card_first_number = card_number[0]
    switch(card_first_number) {
      case "4" :
        card_type = 1;
        break;
      case "5" :
        card_type = 2;  
        break;
      case "2" :
        card_type = 2;
        break;
      default:
        card_type = 3;
    }


    card_number = paytrace.encryptValue(card_number)
    security_code = paytrace.encryptValue(security_code)
  }

  const querytoSend = {...tempQuery, applicant_height, applicant_date_of_birth, level_of_service_doj, level_of_service_fbi, card_number, security_code, expiration_month, expiration_year, card_type, ref: refCode}
  const urlEncodedQuery = new URLSearchParams(querytoSend).toString();
  
  return fetch(`${apiURL}/api/v1/online-enrollments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    },
    body: urlEncodedQuery
  })
}

/**
 * Resend Email
 * @param {string} token 
 */
export const resendEmail = (token) => {
  return fetch(`${apiURL}/api/v1/online-enrollments/${token}/actions/resend-mail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }
  })
}

/**
 * Download PDF
 * @param {string} token 
 */
export const downloadPDF = (token) => {
  return fetch(`${apiURL}/api/v1/online-enrollments/${token}/actions/download`, {
    method: 'POST'
  })
}


/**
* Check ORI
* @param {JSON} query 
*/
export const checkOri = (query) => {
  
  return fetch(`${apiURL}/api/v1/oris/${query}/agencies`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  })
}

/**
* ORI Autocomplete List Request
* @param {String} query 
*/
export const oriAutoComplete = (query) => {
  
  return fetch(`${apiURL}/api/v1/oris?query=${query}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  })
}


/**
* Verify Applicant Account Number
* @param {String} query 
*/
export const verifyAccount = (accountNumber) => {

  return fetch(`${apiURL}/api/v1/accounts/${accountNumber}`, {
    method: 'GET',
  })
}

/**
* Verify Applicant Billing Number
* @param {String} query 
*/
export const verifyBillingNumWithOri = (billingNum, ori) => {

  return fetch(`${apiURL}/api/v1/online-enrollments/actions/check-billing-number-ori?applicant_billing_number=${billingNum}&ori=${ori}`, {
    method: 'HEAD',
  })
}

/**
 * Calculate Fees
 * @param {string} ori 
 * @param {string} authType 
 * @param {string} level_of_service 
 * @param {string} applicant_billing_number 
 */
export const getFees = (ori, authType, level_of_service, applicant_billing_number, original_ati, refCode) => {
  let losdoj = level_of_service.length && level_of_service.includes('DOJ') ? 1 : 0;
  let losfbi = level_of_service.length && level_of_service.includes('FBI') ? 1 : 0;

  return fetch(`${apiURL}/api/v1/online-enrollments/actions/calculate-fee?ori=${encodeURIComponent(ori)}&authorized_applicant_type=${encodeURIComponent(authType)}&level_of_service_doj=${encodeURIComponent(losdoj)}&level_of_service_fbi=${encodeURIComponent(losfbi)}&applicant_billing_number=${encodeURIComponent(applicant_billing_number)}&original_ati=${encodeURIComponent(original_ati)}&ref=${encodeURIComponent(refCode)}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  })
}