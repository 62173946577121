import { getFieldStepIndex } from "./WizardForm";
/**
 * Mark Fields Invalid Or Show Generic Error Message
 * @param {object} jsonResponse 
 * @param {object} formikActions 
 */
export const apiErrorHandler = async function(response, formikActions) {
  const jsonResponse = await response.json();
  // console.log('error handler')
  const { validation, errors } = jsonResponse;
  // console.log(jsonResponse)
  let stepsHasErrors = [];

  /**
  * 1- If Stripe Errors Modify Fields Names to match Form Field
  * 2- Set Fields InValid
  * 3- Push Index of Pages has errors to StepsHas Errors Array
  * 4- Move to Payment Step
  */

  // If Payment gateway error
  if ( errors.length && (errors[0].code === 8 || errors[0].code === 10) ) {
    let {data, message} = errors[0];
    let payTraceError = true;
    let payTraceErrorMsg = ""
    if (data && data.errors) {
      if (data.response_code === 1) { 
        payTraceErrorMsg = Object.values(data.errors)
        .map(errorCodeSet => 
          errorCodeSet.map(errorMsg=> errorMsg)
          .join(", "))
        .join(", ");
      } else {
        payTraceErrorMsg = data.status_message;
      }
      
      this.setState({
        payTraceError,
        payTraceErrorMsg
      });
      
    } else if (data && data.response_code === 102) {
      
      this.setState({
        payTraceError,
        payTraceErrorMsg: "Declined: insufficient fund"
      });

    } else {

      this.setState({
        payTraceError,
        payTraceErrorMsg: message
      });
    }
    
    formikActions.setFieldError('card_number', ' ')
    formikActions.setFieldError('cardholder_name', ' ')
    formikActions.setFieldError('credit_expiry', ' ')
    formikActions.setFieldError('security_code', ' ')
    stepsHasErrors.push(3)
  }

  if ( errors.length && errors[0].Code === 5) {
    validation.map(valError=> {
      switch(valError.Field) {  
        case "invalid_expiry_month":
          valError.Field = "expirationMonth"
          break;
        case "invalid_expiry_year":
          valError.Field = "expirationYear"
          break;
        case "invalid_cvc":
          valError.Field = "cvc"
          break;
          default: 
          valError.Field = "cardNumber"
      }
      formikActions.setFieldError(valError.Field, "Field Is invalid")          
    })

    // Action
    this.setState(prevState=>({
      page: 2 // Index of Payment Step
    }))
    formikActions.setSubmitting(false);

  }

  /**
  * 1- If Sterling Error
  * 2- Set Fields InValid
  * 3- Push Index of Pages has errors to StepsHas Errors Array
  */
  if ( errors.length && errors[0].Code === 6) {

    validation.map(valError=> {
      if ( valError.Field.includes("address.") ) {
        valError.Field = valError.Field.replace("address.","")
        formikActions.setFieldError(valError.Field, "Field Is invalid")     
        stepsHasErrors.push(getFieldStepIndex(valError.Field))
      } else {
        formikActions.setFieldError(valError.Field, "Field Is invalid")
        stepsHasErrors.push(getFieldStepIndex(valError.Field))
      }
    })

    /**
    * If Validation Error Matches any Field Name => Move to field step page
    * Else show Sterling Error Message
    */
    if ( stepsHasErrors.length ) {
      this.setState(prevState=>({
        page: stepsHasErrors[0]
      }))
      
      formikActions.setSubmitting(false)
    } else {
      //TODO Create showSterlingError Func
      console.log('sterling error')
    }
  
  } else {
    
    /**
    * Our API validation Errors
    */
    if (validation) {
      let validationArr = Object.keys(validation);
    
      validationArr.map(valError=> {
        if (valError === 'expiration_month' || valError === 'expiration_year') {
          formikActions.setFieldError('credit_expiry', validation[valError][0])          
        } else if (valError === 'level_of_service_fbi' || valError === 'level_of_service_doj') {
          formikActions.setFieldError('level_of_service', validation[valError][0])          
        } else {
          formikActions.setFieldError(valError, validation[valError][0])          
        }
        stepsHasErrors.push(getFieldStepIndex(valError))
      })
    }
    
  
    // Action
    this.setState(prevState=>({
      page: stepsHasErrors[0]
    }))
    formikActions.setSubmitting(false);
  }
  throw Error(response.statusText)
}

export const apiCheckErrors = async function(response, formikActions) {

  if(!response.ok) {
    const apiHandler = await apiErrorHandler.call(this, response, formikActions);
  } else {
    return response
  }
}